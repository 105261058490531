import React from 'react'

export default function EInvoiceGeneratedSvg({svg_width = '20', svg_height = '25'}) {
    return (
        <svg className={'cursor-pointer'} width={svg_width} height={svg_height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_19974_124652)">
                <mask id="mask0_19974_124652" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                    <path d="M23.4999 23.5V0.500071H0.5V23.5H23.4999Z" fill="white" stroke="white" />
                </mask>
                <g mask="url(#mask0_19974_124652)">
                    <path d="M21.8906 15.2813H2.10937L0.703125 19.0312H23.2968L21.8906 15.2813Z" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M20.4843 2.15629H3.51562C2.739 2.15629 2.10938 2.78586 2.10938 3.56253V15.2812H21.8906V3.56253C21.8906 2.78586 21.2609 2.15629 20.4843 2.15629Z" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M21.8906 21.8438H2.10937C1.33275 21.8438 0.703125 21.2141 0.703125 20.4375V19.0313H23.2968V20.4375C23.2968 21.2141 22.6672 21.8438 21.8906 21.8438Z" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <mask id="mask1_19974_124652" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="4" y="3" width="16" height="12">
                    <path d="M4 3H20V15H4V3Z" fill="white" />
                </mask>
                <g mask="url(#mask1_19974_124652)">
                    <path d="M8.25 12.1641H5.40625C4.8885 12.1641 4.46875 11.8493 4.46875 11.4609V6.53906C4.46875 6.15073 4.8885 5.83594 5.40625 5.83594H8.25" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15.7422 5.83594H18.5859C19.1037 5.83594 19.5234 6.15073 19.5234 6.53906V11.4609C19.5234 11.8493 19.1037 12.1641 18.5859 12.1641H15.7422" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6.34375 5.83594V12.1641" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M16.6797 4.42969H9.17969C8.66194 4.42969 8.24219 4.74448 8.24219 5.13281V12.8672L9.17969 13.5703L10.1172 12.8672L11.0547 13.5703L11.9922 12.8672L12.9297 13.5703L13.8672 12.8672L14.8047 13.5703L15.7422 12.8672V5.13281C15.7422 4.74448 16.1619 4.42969 16.6797 4.42969ZM16.6797 4.42969C17.1974 4.42969 17.6172 4.74448 17.6172 5.13281V12.1641" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <path d="M13.4709 6.736C13.4901 6.78239 13.5 6.8321 13.5 6.88231C13.5 6.93251 13.4901 6.98223 13.4709 7.02861C13.4517 7.075 13.4235 7.11714 13.388 7.15264C13.3525 7.18815 13.3104 7.21631 13.264 7.23552C13.2176 7.25473 13.1679 7.26462 13.1177 7.26462H12.6536C12.7402 7.40538 12.8044 7.55878 12.8439 7.7193H13.1177C13.2191 7.7193 13.3163 7.75958 13.388 7.83128C13.4597 7.90298 13.5 8.00022 13.5 8.10161C13.5 8.20301 13.4597 8.30025 13.388 8.37194C13.3163 8.44364 13.2191 8.48392 13.1177 8.48392H12.844C12.7757 8.75934 12.6357 9.01177 12.4383 9.21557C12.2408 9.41938 11.993 9.56729 11.7199 9.64428L12.7845 10.7589C12.8192 10.7952 12.8464 10.838 12.8645 10.8848C12.8827 10.9316 12.8914 10.9815 12.8903 11.0317C12.8891 11.0819 12.8781 11.1314 12.8578 11.1773C12.8375 11.2233 12.8084 11.2648 12.7721 11.2994C12.7358 11.3341 12.693 11.3613 12.6462 11.3795C12.5994 11.3976 12.5495 11.4063 12.4993 11.4052C12.4491 11.404 12.3996 11.393 12.3537 11.3728C12.3078 11.3525 12.2663 11.3234 12.2316 11.2871L10.6059 9.585C10.5541 9.53086 10.5195 9.46273 10.5061 9.38906C10.4928 9.31539 10.5014 9.23942 10.5308 9.17059C10.5603 9.10175 10.6093 9.04308 10.6717 9.00184C10.7342 8.9606 10.8074 8.93861 10.8823 8.93861H11.2887C11.4425 8.93841 11.5932 8.89588 11.7244 8.81568C11.8556 8.73547 11.9622 8.62069 12.0324 8.48392H10.8823C10.7809 8.48392 10.6837 8.44364 10.612 8.37194C10.5403 8.30025 10.5 8.20301 10.5 8.10161C10.5 8.00022 10.5403 7.90298 10.612 7.83128C10.6837 7.75958 10.7809 7.7193 10.8823 7.7193H12.0324C11.9622 7.58253 11.8556 7.46775 11.7244 7.38754C11.5932 7.30734 11.4425 7.26481 11.2887 7.26462H10.8823C10.7809 7.26462 10.6837 7.22434 10.612 7.15264C10.5403 7.08094 10.5 6.9837 10.5 6.88231C10.5 6.78091 10.5403 6.68367 10.612 6.61198C10.6837 6.54028 10.7809 6.5 10.8823 6.5H13.1177C13.1679 6.5 13.2176 6.50989 13.264 6.5291C13.3104 6.54831 13.3525 6.57647 13.388 6.61197C13.4235 6.64747 13.4517 6.68962 13.4709 6.736Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_19974_124652">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

import React from 'react'
// ** Reactstrap Imports
import { FormFeedback, Input, Label } from 'reactstrap'
import HelpTooltip from '../tooltip/HelpTooltip'
import Tooltip from '../tooltip/Tooltip'
import { detectBrowser, handleFocusTab, isViewMode } from '../../helper/commonFunction'

const InputNumberField = ({ value, placeholder, name, handleChange, handleBlur, secondHandleBlur, disabled, label, handleFocus, tooltipText, maxLength, minLength, min, max, errors, touched, maxNumber, isRequired, className, warningTooltip = false, id, onHoverTooltip, nextFocusId, decimal_places = 0, isDotValid = true }) => {

    /**
     * IW0217
     * This Code is Check Decimal Places and MaxLength for Input Number Field
     */
    const checkDecimalPlacesAndMaxLength = (e) => {
        const new_data = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
        if (Number(maxLength) && value?.length <= (Number(maxLength) + Number(decimal_places) + 1)) {
            if (new_data.includes('.')) {
                if (new_data.length <= (Number(maxLength) + Number(decimal_places))) {
                    handleChange(name, (maxNumber && (new_data > Number(maxNumber))) ? maxNumber : new_data.includes('.') && new_data.split('.')[1].length > decimal_places ? Number(new_data).toFixed(decimal_places) : new_data < 0 ? 0 : new_data)
                } else {
                    handleChange(name, (maxNumber && (new_data > Number(maxNumber))) ? maxNumber : new_data.length > (Number(maxLength) + Number(decimal_places) + 1) ? value : new_data)
                }
            } else {
                if (new_data.length <= Number(maxLength)) {
                    handleChange(name, (maxNumber && (new_data > Number(maxNumber))) ? maxNumber : new_data)
                } else {
                    return new_data
                }
            }
        } else {
            if (maxLength) {
                if (new_data.length <= maxLength) {
                    handleChange(name, (maxNumber && (new_data > Number(maxNumber))) ? maxNumber : new_data)
                } else {
                    return new_data
                }
            } else {
                handleChange(name, (maxNumber && (new_data > Number(maxNumber))) ? maxNumber : new_data)
            }
        }
    }
    
    return (
        <>
            {label ? <><Label key={`${name}.1`} className={`form-label ${tooltipText ? '' : ''}`} htmlFor={label}>
                {label}
                {isRequired && !disabled ? <span className={`${isRequired === true && !disabled ? 'required-star-number' : ''}`}>*</span> : ''}
            </Label>
                {tooltipText ? warningTooltip ? <span className={` ${warningTooltip ? 'munim-label-tooltip-ml' : ''} munim-label-tooltip-cursor`}><Tooltip id={id} label={tooltipText} /></span> : <span className='munim-ml-6'><HelpTooltip id={id ? id : name} label={tooltipText} /></span> : ''} </> : ''}

            <Input
                value={value}
                id={id ? id : name}
                placeholder={disabled && isViewMode() ? '-' : placeholder}
                name={name}
                className={className}

                /**
                 * IW0156
                 * This Code is Check Only MaxLength for Input Amount Field
                 */
                /* onChange={!disabled && (detectBrowser() !== 'Safari' && detectBrowser() !== 'Firefox') ? (e) => (maxLength ? e.target.value.length <= maxLength ? handleChange(name, (maxNumber && (e.target.value > Number(maxNumber))) ? maxNumber : e.target.value) : e.target.value : handleChange(name, (maxNumber && (e.target.value > Number(maxNumber))) ? maxNumber : e.target.value)) : () => { }} */

                /**
                 * IW0156
                 * This Code is Check Decimal Places and MaxLength for Input Amount Field
                 */
                /* onChange={!disabled && (detectBrowser() !== 'Safari' && detectBrowser() !== 'Firefox') ? Number(maxLength) && value?.length <= (Number(maxLength) + Number(decimal_places) + 1) ? (e) => (e.target.value.includes('.') ? e.target.value.length <= (Number(maxLength) + Number(decimal_places)) ? handleChange(name, (maxNumber && (e.target.value > Number(maxNumber))) ? maxNumber : e.target.value.includes('.') && e.target.value.split('.')[1].length > decimal_places ? Number(e.target.value).toFixed(decimal_places) : e.target.value < 0 ? 0 : e.target.value) : handleChange(name, (maxNumber && (e.target.value > Number(maxNumber))) ? maxNumber : e.target.value.length > (Number(maxLength) + Number(decimal_places) + 1) ? value : e.target.value) : (e.target.value.length <= Number(maxLength) ? handleChange(name, (maxNumber && (e.target.value > Number(maxNumber))) ? maxNumber : e.target.value) : e.target.value)) : (e) => (maxLength ? e.target.value.length <= maxLength ? handleChange(name, (maxNumber && (e.target.value > Number(maxNumber))) ? maxNumber : e.target.value) : e.target.value : handleChange(name, (maxNumber && (e.target.value > Number(maxNumber))) ? maxNumber : e.target.value)) : () => { }} */ 

                onChange={!disabled ? (e) => checkDecimalPlacesAndMaxLength(e) : () => { }}

                onBlur={secondHandleBlur ? () => { handleBlur(name); secondHandleBlur() } : () => handleBlur(name)}
                autoComplete='true'
                onFocus={handleFocus}
                type={(detectBrowser() === 'Safari' || detectBrowser() === 'Firefox') ? 'text' : 'number'}
                disabled={disabled}
                // onInput={!disabled && (detectBrowser() === 'Safari' || detectBrowser() === 'Firefox') ? (e) => {
                //     const new_data = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
                //     return maxLength ? new_data.length <= maxLength ? handleChange(name, (maxNumber && (new_data > Number(maxNumber))) ? maxNumber : new_data) : new_data : handleChange(name, (maxNumber && (new_data > Number(maxNumber))) ? maxNumber : new_data)
                // } : () => {}}
                onKeyDown={(event) => {
                    if (/^[0-9\b]+$/.test(event.key)) {
                    } else if (/^[.\b]+$/.test(event.key) && event.target.value.split('.').length < 2) {
                    } else if (event.keyCode === 38 || event.keyCode === 40) {
                        event.preventDefault()
                    } else {
                    }
                    if (nextFocusId) {
                        handleFocusTab(event, nextFocusId)
                    }
                    if (isDotValid ? ["e", "E", "+", "-"].includes(event.key) : ["e", "E", "+", "-", '.'].includes(event.key)) {
                        event.preventDefault()
                    }
                }}
                onWheelCapture={(event) => event.target.blur()}
                minLength={minLength}
                min={min}
                max={max}
                invalid={errors && touched && true}
            />
            {onHoverTooltip ? <div className='error-hover-tooltip'></div> : ''}
            {onHoverTooltip ? errors && touched && (
                <div className='custom-tooltip'>
                    <div className='custom-tooltip-label'>
                        <p> {errors}</p>
                    </div>
                </div>
            ) : errors && touched && (
                <FormFeedback tooltip={true}>
                    {errors}
                </FormFeedback>
            )}

        </>
    )
}

export default InputNumberField

import React from 'react'

const ReceiptSvg = () => {
  return (
    <svg className={'cursor-pointer receipt-svg'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.625 1.536c-1.31 0-2.358 1.04-2.358 2.308v8.503c0 1.269 1.049 2.309 2.358 2.309h6.84a.636.636 0 0 0 .44-.176.6.6 0 0 0 0-.864.636.636 0 0 0-.44-.176h-6.84a1.099 1.099 0 0 1-1.106-1.093V3.844A1.1 1.1 0 0 1 3.625 2.75h15.584a1.1 1.1 0 0 1 1.106 1.093v4.71a.6.6 0 0 0 .185.431.636.636 0 0 0 .881 0 .6.6 0 0 0 .186-.432v-4.71c0-1.267-1.049-2.307-2.358-2.307H3.625Z" fill="#000" stroke="#000" strokeWidth=".3" /><path d="M1.445 4.46a.608.608 0 0 0 .43 1.038h18.314a.608.608 0 0 0 0-1.215H1.875a.608.608 0 0 0-.43.178Zm1.831 4.58a.608.608 0 0 0 .43 1.037h1.832a.608.608 0 0 0 0-1.216H3.706a.608.608 0 0 0-.43.178Zm0 2.289a.608.608 0 0 0 .43 1.037H7.37a.608.608 0 0 0 0-1.216H3.706a.608.608 0 0 0-.43.179Z" fill="#000" stroke="#000" strokeWidth=".3" /><circle cx="18" cy="16" r="5.25" stroke="#000" strokeWidth="1.5" /><path d="M17.44 18.592a.558.558 0 1 0 1.116 0v-5.184a.558.558 0 1 0-1.115 0v5.184Z" fill="#000" stroke="#000" strokeWidth=".3" /><path d="M15.408 16.556h5.184a.558.558 0 1 0 0-1.115h-5.184a.558.558 0 1 0 0 1.115Z" fill="#000" stroke="#000" strokeWidth=".3" /></svg>
  )
}

export default ReceiptSvg

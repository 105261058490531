import React from 'react'

const ContraSvg = () => {
    return (
        <svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M332.878 506H330.112C235.661 506 190.137 473.4 182.266 377.977C181.415 367.996 187.797 358.989 196.732 358.016C205.453 357.042 213.324 364.588 214.175 374.569C220.344 451.005 251.828 469.486 330.325 469.486H333.09C419.671 469.486 450.304 434.432 450.304 335.357V176.643C450.304 77.5677 419.671 42.5141 333.09 42.5141H330.325C251.403 42.5141 219.919 61.482 214.175 139.379C213.112 149.359 205.879 156.906 196.732 155.932C194.635 155.744 192.591 155.085 190.718 153.992C188.844 152.899 187.178 151.393 185.814 149.561C184.451 147.729 183.416 145.607 182.771 143.317C182.126 141.026 181.882 138.613 182.053 136.214C189.286 39.3301 235.023 6 330.112 6H332.878C437.327 6 482 57.1198 482 176.643V335.357C482 454.88 437.327 506 332.878 506Z" fill="currentColor"/>
            <path d="M70.7661 214.701H345.03C353.76 214.701 361 208.479 361 200.977C361 193.475 353.76 187.253 345.03 187.253H70.7661C66.5305 187.253 62.4684 188.699 59.4734 191.273C56.4784 193.847 54.7958 197.337 54.7958 200.977C54.7958 204.617 56.4784 208.108 59.4734 210.681C62.4684 213.255 66.5305 214.701 70.7661 214.701Z" fill="currentColor"/>
            <path d="M118.251 276C122.297 276 126.343 274.719 129.537 271.974C132.507 269.392 134.173 265.907 134.173 262.276C134.173 258.645 132.507 255.161 129.537 252.578L69.4885 200.977L129.537 149.376C135.712 144.07 135.712 135.286 129.537 129.98C123.362 124.673 113.141 124.673 106.965 129.98L35.6314 191.279C29.4562 196.586 29.4562 205.369 35.6314 210.675L106.965 271.974C110.16 274.719 114.205 276 118.251 276Z" fill="currentColor"/>
            <path d="M320.234 324.701H45.9703C37.2399 324.701 30 318.479 30 310.977C30 303.475 37.2399 297.253 45.9703 297.253H320.234C324.469 297.253 328.532 298.699 331.527 301.273C334.522 303.847 336.204 307.337 336.204 310.977C336.204 314.617 334.522 318.108 331.527 320.681C328.532 323.255 324.469 324.701 320.234 324.701Z" fill="currentColor"/>
            <path d="M272.749 386C268.703 386 264.657 384.719 261.463 381.974C258.493 379.392 256.827 375.907 256.827 372.276C256.827 368.645 258.493 365.161 261.463 362.578L321.512 310.977L261.463 259.376C255.288 254.07 255.288 245.286 261.463 239.98C267.638 234.673 277.859 234.673 284.035 239.98L355.369 301.279C361.544 306.586 361.544 315.369 355.369 320.675L284.035 381.974C280.84 384.719 276.795 386 272.749 386Z" fill="currentColor"/>
        </svg>
    )
}

export default ContraSvg
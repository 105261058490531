import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    quick_link_data: []
}

export const quickLinkSlice = createSlice({
    name: 'einvoice',
    initialState,
    reducers: {
        setQuickLinkData: (state, action) => {
            state.quick_link_data = action.payload
        }
    }
})

export const { setQuickLinkData } = quickLinkSlice.actions

export default quickLinkSlice.reducer
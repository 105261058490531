let apiEndpoint
let commonApi
let gstApi
let sampleFileApi
let socketApi
let staffError = 'addStaffError'
let demouser = ''
let appRedirection = ''
let whatsappLinkApi
let generateotp = function () { window.open('https://themunim.com/helpdesk/how-can-i-activate-or-de-activate-an-api-session/') }
const exportFileApi = 'https://munim.blr1.digitaloceanspaces.com/'
const liveDomain = 'app.themunim.com'
const desktopAppDownloadUrl = 'https://themunim.com/download/'
const downloadSampleFilePath = `${exportFileApi}app/static/`
const hostname = window.location.hostname
const isLive = window.location.hostname !== 'localhost'
if (hostname === 'app.themunim.com') {
    whatsappLinkApi = 'https://service.themunim.com/'
    apiEndpoint = 'https://api.themunim.com/api'
    commonApi = 'https://api.themunim.com/'
    gstApi = 'https://gst.themunim.com'
    socketApi = 'https://api.themunim.com/'
    sampleFileApi = `${exportFileApi}app/live/`
} else if (hostname === 'stage.themunim.com') {
    whatsappLinkApi = 'https://stageapi.themunim.com/'
    apiEndpoint = 'https://stageapi.themunim.com/api'
    commonApi = 'https://stageapi.themunim.com/'
    gstApi = 'https://stagegst.themunim.com'
    socketApi = 'https://stageapi.themunim.com/'
    sampleFileApi = `${exportFileApi}app/staging/`
} else if (hostname === 'devreact.themunim.com') {
    whatsappLinkApi = 'https://devapi.themunim.com/'
    apiEndpoint = 'https://devapi.themunim.com/api'
    commonApi = 'https://devapi.themunim.com/'
    gstApi = 'https://devgst.themunim.com'
    socketApi = 'https://devapi.themunim.com/'
    sampleFileApi = `${exportFileApi}app/dev/`
} else if (hostname === 'qa.themunim.com') {
    whatsappLinkApi = 'https://qaapi.themunim.com/'
    apiEndpoint = 'https://qaapi.themunim.com/api'
    commonApi = 'https://qaapi.themunim.com/'
    gstApi = 'https://devgst.themunim.com'
    socketApi = 'https://qaapi.themunim.com/'
    sampleFileApi = `${exportFileApi}app/qa/`
} else if (hostname === 'dev.themunim.com') {
    whatsappLinkApi = 'https://devapi.themunim.com/'
    apiEndpoint = 'https://devapi.themunim.com/api'
    commonApi = 'https://devapi.themunim.com/'
    gstApi = 'https://devgst.themunim.com'
    socketApi = 'https://devapi.themunim.com'
    sampleFileApi = `${exportFileApi}app/dev/`
} else if (hostname === 'localhost') {
    whatsappLinkApi = 'https://localhost:8070/'
    apiEndpoint = 'http://localhost:8070/api'
    commonApi = 'http://localhost:8070/'
    gstApi = 'http://localhost:4242/'
    sampleFileApi = `${exportFileApi}app/local/`
} else if (hostname === 'demo.themunim.com') {
    whatsappLinkApi = 'https://demoapi.themunim.com/'
    apiEndpoint = 'https://demoapi.themunim.com/api'
    commonApi = 'https://demoapi.themunim.com/'
    socketApi = 'https://demoapi.themunim.com/'
    sampleFileApi = `${exportFileApi}app/demo/`
    staffError = 'addDemoStaffError'
    demouser = 'Demo_'
    appRedirection = function handleAppRedirection() {
        window.open("https://app.themunim.com", "_blank")
    }
    generateotp = appRedirection
}

module.exports = {
    apiEndpoint,
    commonApi,
    gstApi,
    sampleFileApi,
    socketApi,
    exportFileApi,
    staffError,
    demouser,
    appRedirection,
    generateotp,
    isLive,
    whatsappLinkApi,
    downloadSampleFilePath,
    desktopAppDownloadUrl,
    liveDomain
}

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiCall, GetApiCall, to_encrypt, to_decrypt } from "../../../helper/axios"
import CommonApiEndPoint from "../../../helper/commonApiEndPoint"

/** Get Main User Profiles */
export const getUserProfile = createAsyncThunk('appChat/getTasks', async () => {
  const header = { 'access-token': localStorage.getItem('access_token') }
  const response = await GetApiCall('GET', CommonApiEndPoint.get_profile, header)
  return response.data
})

/** Get Contact User List Related Current Company */
export const getContacts = createAsyncThunk('appChat/getContacts', async (id) => {
  const header = { 'access-token': localStorage.getItem('access_token') }
  const response = await GetApiCall('GET', `${CommonApiEndPoint.fetch_staff_list}?id=${id}`, header)
  return response.data
})

/** Get Active Chat User List */
export const getChats = createAsyncThunk('appChat/getChats', async (obj) => {
  const header = { 'access-token': localStorage.getItem('access_token') }
  const final_obj = to_encrypt(obj)
  const final_data = {
    data: final_obj
  }
  const response = await ApiCall('POST', CommonApiEndPoint.get_user_chat_list, final_data, header)
  if (response.data.message === "Data not found.") {
    return []
  } else {
    const decrypt_data = to_decrypt(response.data.data)
    return decrypt_data
  }
})

/** Get Messages */
export const selectChat = createAsyncThunk('appChat/selectChat', async (id) => {
  const header = { 'access-token': localStorage.getItem('access_token') }
  const obj = {
    conversation_id: id
  }
  const final_obj = to_encrypt(obj)
  const final_data = {
    data: final_obj
  }
  const response = await ApiCall('POST', CommonApiEndPoint.get_messages, final_data, header)
  return response.data
})

/** Send Message */
export const sendMsg = createAsyncThunk('appChat/sendMsg', async (obj) => {
  const header = { 'access-token': localStorage.getItem('access_token') }
  const final_obj = to_encrypt(obj)
  const final_data = {
    data: final_obj
  }
  const response = await ApiCall('POST', CommonApiEndPoint.send_messages, final_data, header)
  if (response.data.status === "success") {
    return response.data
  }
})

/** Add New Conversation */
export const addNewUser = createAsyncThunk('appChat/addNewUser', async (obj, { dispatch }) => {
  const header = { 'access-token': localStorage.getItem('access_token') }
  const final_obj = to_encrypt(obj)
  const final_data = {
    data: final_obj
  }
  const response = await ApiCall('POST', CommonApiEndPoint.add_conversation, final_data, header)
  const chatObj = {
    company_id: obj.company_id,
    member_id: obj.current_user_id
  }
  await dispatch(getChats(chatObj))
  return response.data
})

/** get all unRead messages */
export const getUnReadMessage = createAsyncThunk('appChat/getUnReadMessage', async (currentCompanyId) => {
  const header = { 'access-token': localStorage.getItem('access_token') }
  const response = await GetApiCall('GET', `${CommonApiEndPoint.get_un_read_message}?id=${currentCompanyId}`, header)
  return response.data
})

function spliceIntoChunks(arr, chunkSize) {
  const res = []
  while (arr.length > 0) {
    const chunk = arr.splice(0, chunkSize)
    res.push(chunk.reverse())
  }
  return res
}

export const appChatSlice = createSlice({
  name: 'appChat',
  initialState: {
    chats: [],
    contacts: [],
    userProfile: {},
    currentConversationUser: {},
    selectedUser: {},
    noUserChat: '',
    unseenMsg: [],
    nevArrivalMessage: {},
    chatArrivalMessage: {},
    socketConn: {}
  },
  reducers: {
    currentConversation(state, action) {
      state.currentConversationUser = action.payload
      window.currentConversation = action.payload?.id
    },
    unSeenMessages(state, action) {
      state.unseenMsg = action.payload
    },
    setNevArrivalMessage(state, action) {
      state.nevArrivalMessage = action.payload
    },
    setChatArrivalMessage(state, action) {
      state.chatArrivalMessage = action.payload
    },
    setSelectedUser(state, action) {
      state.selectedUser = action.payload
    },
    setSocketData(state, action) {
      state.socketConn = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload.data
      })
      .addCase(getContacts.fulfilled, (state, action) => {
        if (action.payload.message === "Something went wrong!") {
          state.contacts = []
        } else {
          state.contacts = action.payload.data
        }
      })
      .addCase(getChats.fulfilled, (state, action) => {
        state.chats = action.payload
      })
      .addCase(addNewUser.fulfilled, (state, action) => {
        console.log("action", action.payload)
      })
      .addCase(selectChat.fulfilled, (state, action) => {
        if (action.payload.message === "Data not found.") {
          state.noUserChat = 'No Data Found'
        } else {
          const decrypt_data = to_decrypt(action.payload.data)
          const msg_array = decrypt_data.message_data
          msg_array.reverse()
          /** we Divide Message Array in Part Each Part Has 10 Message */
          const split_messages = spliceIntoChunks(msg_array, 30)
          state.selectedUser = split_messages
          state.noUserChat = ''
        }
      })
      .addCase(getUnReadMessage.fulfilled, (state, action) => {
        const decrypt_data = (action.payload?.statusCode !== 400 && action.payload?.data?.length > 0) ? to_decrypt(action.payload.data) : action.payload.data
        state.unseenMsg = decrypt_data
      })
  }
})

export const { currentConversation, unSeenMessages, setNevArrivalMessage, setChatArrivalMessage, setSelectedUser, setSocketData } = appChatSlice.actions
export default appChatSlice.reducer

import React from 'react'

const PaperEditSvg = () => {
    return (
        <svg className={'cursor-pointer'} width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.83227 14.169H5.14618C4.83466 14.169 4.58203 13.9165 4.58203 13.605C4.58203 13.2935 4.83461 13.0409 5.14618 13.0409H9.83227C10.1438 13.0409 10.3964 13.2935 10.3964 13.605C10.3964 13.9165 10.1438 14.169 9.83227 14.169ZM9.83227 10.1818H5.14618C4.83466 10.1818 4.58203 9.92929 4.58203 9.61777C4.58203 9.30624 4.83461 9.05371 5.14618 9.05371H9.83227C10.1438 9.05371 10.3964 9.30624 10.3964 9.61777C10.3964 9.92929 10.1438 10.1818 9.83227 10.1818Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5" />
            <path d="M13.2838 21.0751L11.1687 18.2297H4.21731C2.44326 18.2297 1 16.7885 1 15.017V8.18629C1 6.41482 2.44326 4.97363 4.21731 4.97363H11.0153C11.3268 4.97363 11.5795 5.22616 11.5795 5.53769C11.5795 5.84921 11.3269 6.10174 11.0153 6.10174H4.21731C3.06546 6.10174 2.1283 7.03688 2.1283 8.18629V15.017C2.1283 16.1665 3.06546 17.1016 4.21731 17.1016H11.4523C11.6307 17.1016 11.7987 17.186 11.905 17.3292L13.7306 19.785L15.5048 17.3349C15.5571 17.2626 15.6258 17.2038 15.7052 17.1633C15.7846 17.1227 15.8726 17.1016 15.9617 17.1016H19.7737C20.9256 17.1016 21.8628 16.1665 21.8628 15.017V8.31241C21.8628 8.00089 22.1153 7.74836 22.4269 7.74836C22.7385 7.74836 22.9911 8.00089 22.9911 8.31241V15.017C22.9911 16.7885 21.5478 18.2297 19.7737 18.2297H16.2498L14.1935 21.0694C14.0875 21.2158 13.7137 21.5208 13.2838 21.0751Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5" />
            <path d="M21.8994 1.50795C20.7527 0.637539 18.9808 0.918415 18.1592 2.10068L13.117 9.04962C13.0591 9.13049 13.0231 9.22489 13.0123 9.32373L12.5866 13.5039C12.5309 13.9198 12.9984 14.2583 13.3758 14.0769L17.2176 12.3801C17.3084 12.3391 17.3871 12.2755 17.4463 12.1954L22.4909 5.2432C23.3571 4.05026 23.0917 2.37469 21.8994 1.50795ZM14.0354 10.4139L15.8652 11.7441L13.8074 12.653L14.0354 10.4139ZM21.5778 4.58052L16.8646 11.0759L14.3612 9.25599L19.0724 2.76331C19.5476 2.07953 20.5726 1.91692 21.236 2.42043C21.9254 2.92159 22.0788 3.89053 21.5778 4.58052Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5" />
            <path d="M13.4862 6.27278C13.8497 6.27278 14.1444 5.97813 14.1444 5.61466C14.1444 5.25119 13.8497 4.95654 13.4862 4.95654C13.1228 4.95654 12.8281 5.25119 12.8281 5.61466C12.8281 5.97813 13.1228 6.27278 13.4862 6.27278Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5" />
        </svg>
    )
}

export default PaperEditSvg

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    einvoice_action: 1
}

export const einvoiceSlice = createSlice({
    name: 'einvoice',
    initialState,
    reducers: {
        setEinvoiceAction: (state, action) => {
            state.einvoice_action = action.payload
        }
    }
})

export const { setEinvoiceAction } = einvoiceSlice.actions

export default einvoiceSlice.reducer
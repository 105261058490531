import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    customer: '',
    customerList: []
}

export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        setCustomer: (state, action) => {
            state.customer = action.payload
        },
        setCustomerList: (state, action) => {
            state.customerList = action.payload
        }
    }
})

export const { setCustomer, setCustomerList } = customerSlice.actions

export default customerSlice.reducer
import React from 'react'

export default function EwayGeneratedSvg({ svg_width = '20', svg_height = '25', color, id = '' }) {
    return (
        <svg className={'cursor-pointer'} id={id} width={svg_width} height={svg_height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.9257 11.6856L21.8906 7.61522C21.2912 6.41653 20.0864 5.67188 18.7462 5.67188H15.5625V4.96875C15.5625 3.80564 14.6162 2.85938 13.4531 2.85938H2.10938C0.946266 2.85938 0 3.80564 0 4.96875V16.2188C0 17.3819 0.946266 18.3281 2.10938 18.3281H2.8125C2.8125 19.8789 4.07419 21.1406 5.625 21.1406C7.17581 21.1406 8.4375 19.8789 8.4375 18.3281H15.5625C15.5625 19.8789 16.8242 21.1406 18.375 21.1406C19.9258 21.1406 21.1875 19.8789 21.1875 18.3281H21.8906C23.0537 18.3281 24 17.3819 24 16.2188V12C24 11.8909 23.9708 11.7758 23.9257 11.6856ZM5.625 19.7344C4.84959 19.7344 4.21875 19.1035 4.21875 18.3281C4.21875 17.5527 4.84959 16.9219 5.625 16.9219C6.40041 16.9219 7.03125 17.5527 7.03125 18.3281C7.03125 19.1035 6.40041 19.7344 5.625 19.7344ZM14.1562 16.9219H8.05912C7.57205 16.0821 6.6637 15.5156 5.625 15.5156C4.5863 15.5156 3.67795 16.0821 3.19088 16.9219H2.10938C1.72167 16.9219 1.40625 16.6065 1.40625 16.2188V14.1094H14.1562V16.9219ZM14.1562 12.7031H1.40625V4.96875C1.40625 4.58105 1.72167 4.26562 2.10938 4.26562H13.4531C13.8408 4.26562 14.1562 4.58105 14.1562 4.96875V12.7031ZM18.375 7.07812H18.7462C19.5503 7.07812 20.2732 7.52494 20.6329 8.24414L22.1592 11.2969H18.375V7.07812ZM18.375 19.7344C17.5996 19.7344 16.9688 19.1035 16.9688 18.3281C16.9688 17.5527 17.5996 16.9219 18.375 16.9219C19.1504 16.9219 19.7812 17.5527 19.7812 18.3281C19.7812 19.1035 19.1504 19.7344 18.375 19.7344ZM22.5938 14.1094H21.8906C21.5023 14.1094 21.1875 14.4242 21.1875 14.8125C21.1875 15.2008 21.5023 15.5156 21.8906 15.5156H22.5938V16.2188C22.5938 16.6065 22.2783 16.9219 21.8906 16.9219H20.8091C20.322 16.0821 19.4137 15.5156 18.375 15.5156C17.3363 15.5156 16.428 16.0821 15.9409 16.9219H15.5625V7.07812H16.9688V12C16.9688 12.3883 17.2836 12.7031 17.6719 12.7031H22.5938V14.1094Z" fill={color ? color : 'black'} />
            <path d="M12.0469 5.67188H3.51562C3.12731 5.67188 2.8125 5.98669 2.8125 6.375C2.8125 6.76331 3.12731 7.07812 3.51562 7.07812H12.0469C12.4352 7.07812 12.75 6.76331 12.75 6.375C12.75 5.98669 12.4352 5.67188 12.0469 5.67188ZM12.0469 8.48438H3.51562C3.12731 8.48438 2.8125 8.79919 2.8125 9.1875C2.8125 9.57581 3.12731 9.89062 3.51562 9.89062H12.0469C12.4352 9.89062 12.75 9.57581 12.75 9.1875C12.75 8.79919 12.4352 8.48438 12.0469 8.48438Z" fill={color ? color : 'black'} />
        </svg>
    )
}

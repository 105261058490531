import React from 'react'

const HelpSvg = ({svg_width = '18', svg_height = '18'}) => {
    return (
        <svg className={'cursor-pointer'} xmlns="http://www.w3.org/2000/svg" width={svg_width} height={svg_height} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <circle cx="12" cy="12" r="10"></circle>
            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
            <line x1="12" y1="16" x2="12.01" y2="16"></line>
        </svg>
    )
}

export default HelpSvg
import React, { useState } from 'react'
import { HelpCircle, Info } from 'react-feather'
import { Tooltip } from 'reactstrap'
import HelpBlackSvg from '../common_svg/HelpBlackSvg'

const HelpTooltip = ({ label, id, circle, info, isRequired, color, className }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false)
    return (
        <>
            <span className={`cursor-pointer m-0 ${isRequired ? 'munim-tooltip-ml-6 munim-tooltip-pl-8' : ''}`} id={`tooltip-${id}`}>
                <span className='munim-svg munim-svg-help-icon'>
                    <Tooltip
                        className={className}
                        placement='top'
                        isOpen={tooltipOpen}
                        target={`tooltip-${id}`}
                        toggle={() => setTooltipOpen(!tooltipOpen)}
                    >
                        {label}
                    </Tooltip>
                    {circle ? <HelpBlackSvg color={color} /> : info ? <Info height={'16'} width={'16'} stroke={color ? color : "black"} onClick={() => setTooltipOpen(!tooltipOpen)} /> : <HelpCircle height={'16'} width={'16'} stroke={color ? color : "black"} onClick={() => setTooltipOpen(!tooltipOpen)} />}
                </span >
            </span >
        </>
    )
}

export default HelpTooltip